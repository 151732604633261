.new-terminal {
  flex: 4;
}

.add-terminal-title {
  margin-left: 5%;
}

.add-terminal-form {
  margin: 10px auto;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.personal-details,
.business-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 0 50px;
}

.add-terminal-item {
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-right: 20px;
}

.add-terminal-item > label {
  color: gray;
  font-weight: 600;
  margin-bottom: 10px;
}

.add-terminal-item > input {
  padding: 6px;
  width: 100%;
}

.add-terminal-item > select {
  padding: 10px;
}

.edit-terminal-button-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)) !important;
  gap: 0 20px !important;
  max-width: 910px;
  margin-top: 40px;
  margin-bottom: 70px;
}

.add-terminal-button {
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  max-width: 414px;
  width: 100%;
  height: 40px;
}

.disable-terminal-button {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid darkblue;
  color: darkblue;
  background-color: white;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  max-width: 414px;
  width: 100%;
  height: 40px;
}

.disable-terminal-button.inactive {
  border: 1px solid red;
  color: red;
}

h4 {
  margin-bottom: 10px;
  margin-top: 20px;
}
