.new-merchant {
  flex: 4;
}

.add-merchant-title {
  margin-left: 5%;
}

.add-merchant-form {
  margin: 10px auto;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.edit-merchant-button-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(414px, 1fr)) !important;
  gap: 0 20px !important;
  max-width: 910px;
  margin-top: 40px;
  margin-bottom: 70px;
}

.add-merchant-button {
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  max-width: 414px;
  width: 100%;
  height: 40px;
}

.disable-merchant-button {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid darkblue;
  color: darkblue;
  background-color: white;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  max-width: 414px;
  width: 100%;
  height: 40px;
}

.disable-merchant-button.inactive {
  border: 1px solid red;
  color: red;
}
