.terminal {
    flex: 5;
    padding: 20px;
}

.terminal-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.terminal-add-button {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: darkblue;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.terminals-list {
    padding-top: 20px;
}