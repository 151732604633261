.container {
  display: flex;
}

.others {
  flex: 4;
  background-color: blueviolet;
}

.link {
  text-decoration: none;
  color: inherit;
}

/* OVERRIDES FOR WEIRD INPUT STYLES */
input {
  border-color: unset;
  box-shadow: unset;
  appearance: none !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  text-indent: 4px;
  font-size: 14px;
  color: black;
}

input::placeholder {
  opacity: 0.4;
}

/* OVERRIDES FOR WEIRD REACT-SELECT INPUT STYLES */
#active > div.css-1pahdxg-control {
  border-color: var(--focus-ring-color) !important;
  box-shadow: 0 0 0 1px var(--focus-ring-color) !important;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.modal-body-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
}
