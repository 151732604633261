.transaction {
  flex: 5;
  padding: 20px;
}

.transaction-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-add-button {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: darkblue;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.transactions-list {
  padding-top: 20px;
}
