.update_staff_form {
  display: grid;
  gap: 15px;
}

.edit_staff_button_container {
  margin-top: 15px;
}

.edit_staff_button {
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  height: 40px;
}

.new_password {
  color: darkblue;
  font-weight: bold;
  font-size: 18px;
}

.web_master {
  color: red;
  font-size: 14px;
  text-align: center;
}

.copy_button {
  appearance: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
}
