.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.add-merchant-item {
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-right: 20px;
}

.add-merchant-item > label {
  color: gray;
  font-weight: 600;
  margin-bottom: 10px;
}

.add-merchant-item > input {
  padding: 6px;
  width: 100%;
}

.add-merchant-item > select {
  padding: 10px;
}

h4 {
  margin-bottom: 10px;
  margin-top: 20px;
}

.personal-details,
.business-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
  gap: 0 80px !important;
}
