.home {
    flex: 5;
    padding: 20px;
}

.transactions-title {
    font-size: 20px;
    margin-bottom: 10px;
}

.transactions-list {
    padding: 20px;
    margin-top: 20px;
}