.container{
    margin: 10px;
}

.header {
    display: flex;
    position: absolute;
}

.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.login-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
}

  
.login-form-content {
    padding-left: 12%;
    padding-right: 12%;
}

.login-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
}


label {
    font-size: 14px;
    font-weight: 600;
    color: gray;
}

.login-input {
    margin-bottom: 10px;
    border: none;
}

input {
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    height: 30px;
}

.login-button{
    margin-top: 10px;
    margin-left: 5px;
    margin-bottom: 10px;
    border: none;
    padding: 5px;
    border-radius: 25px;
    background-color: darkblue;
    color:white;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    height: 45px;
}

.form-error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}