.staff {
  flex: 5;
  padding: 20px;
}

.staff-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.staff-add-button {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: darkblue;
  border: 1px solid darkblue;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.staff-add-button.disable {
  border: 1px solid red;
  color: red;
  background-color: white !important;
}

.staff-add-button.enable {
  border: 1px solid green;
  color: green;
  background-color: white !important;
}

.staff-list {
  padding-top: 20px;
}

.reset-staff-password {
  border: 1px solid red;
  color: red;
  background-color: white !important;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 30px;
}
