.refresh {
  cursor: pointer;
  appearance: none;
  border: none;
  margin: 0 10px;
  color: #333333;
  border-radius: 4px;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 4rem);
}

.container h2 {
  font-size: 10rem;
}
.message {
  border: 2px #333333 solid;
  border-radius: 5px;
  font-size: 24px;
  color: #333333;
  text-align: center;
  padding: 20px;
  display: flex;
}
