.sidebar {
  flex: 1;
  height: calc(100vh - 50px);
  background-color: rgb(251, 251, 255);
  position: sticky;
  top: 50px;
}

.sidebar-wrapper {
  padding: 20px;
  color: #555;
}

.sidebar-menu {
  margin-bottom: 10px;
}

.sidebar-title {
  font-size: 15px;
  color: rgb(187, 186, 186);
}

.sidebar-list {
  list-style: none;
  padding: 5px;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.text-link.active .sidebar-list-items {
  background-color: rgb(240, 240, 255);
  font-weight: bold;
}

.text-link.active {
  background-color: rgb(240, 240, 255);
}

.sidebar-list-items:hover {
  background-color: rgb(240, 240, 255);
}

.sidebar-list-items {
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid transparent;
}

.sidebar-icon {
  margin-right: 5px;
  font-size: 20px !important;
  vertical-align: bottom;
}

#logout {
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  color: red;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 35px;
  font-size: 14px;
}
