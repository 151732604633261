.featured {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.featured-item {
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.featured-title{
    font-size: 20px;
}

.featured-card-container{
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.featured-count{
    font-size: 30px;
    font-weight: 600;
}

.text-link {
    color: inherit;
    text-decoration: inherit;
}

.featured-sub{
    font-size: 15px;
    color: gray;
}