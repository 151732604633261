.topbar {
  width: 100%;
  height: 50%;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
}

.topbar-wrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 30px;
  color: darkblue;
  cursor: pointer;
}

.top-right {
  display: flex;
  align-items: center;
}

p.current-user {
  text-transform: capitalize;
  color: darkblue;
  font-weight: bold;
}
