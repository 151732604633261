.new-merchant {
  flex: 4;
}

.add-merchant-title {
  margin-left: 5%;
}

.add-merchant-form {
  margin: 10px auto;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.add-merchant-button-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
  gap: 0 80px !important;
}

.add-merchant-button {
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  max-width: 414px;
  width: 100%;
  height: 40px;
}
